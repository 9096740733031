<template>
  <div style="padding-bottom: 60px">
    <div style="display:flex;justify-content: space-between">
      <!--学员信息-->
      <div  style="padding-left: 40px;width: 46%">
        <div style="font-weight: bold;font-size: 15px">学员信息</div>
        <div v-if="item.studentRecord!=null" style="padding: 0px 0 0 20px">
          <div class="padding-top">学员Id：{{ item.studentRecord.id }}</div>
          <div class="padding-top">学员姓名：{{ item.studentName }} /
            {{ item.studentRecord.studentSex == '0' ? '女' : '男' }}
          </div>
          <div class="padding-top">学员电话：{{ item.studentPhone }}</div>
          <div class="padding-top">学员应届：{{ item.studentRecord.studentGrade }}</div>
          <div class="padding-top">学员班级：{{ item.studentRecord.studentClass }}</div>
          <div class="padding-top">学员学校：{{ item.studentRecord.studentSchool }}</div>
        </div>
      </div>
      <!--缴费信息-->
      <div style="padding: 0 40px;width: 54%">
        <div style="font-weight: bold;font-size: 15px;">缴费信息</div>
        <div style="padding: 0px 0 0 20px">
          <div class="padding-top">收款老师：{{ item.coachName }} <span style="margin-left:20px">ID：{{ item.coachId }}</span>
          </div>
          <div class="padding-top">收款金额：{{ item.proceedsSum }} 元</div>
          <div class="padding-top">订单来源：{{ item.sourceName }}</div>
          <div class="padding-top">下单类型：{{ item.submitType }}</div>
          <div class="padding-top">收款日期：{{ item.collectionTime }}</div>
          <div class="padding-top">商户单号：{{ item.transaction }}</div>
          <div style="display: flex;flex-wrap: wrap;justify-content: space-between">
            <div class="padding-top" style="text-align: left;" v-for="items in item.divideList" :key="items.id">
              <div v-if="items.id">
                <span>分成人：{{ items.id }}</span>
                <el-divider direction="vertical"></el-divider>
                <span> 分成金额：{{ items.money }} 元</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--器材相关信息-->
      <div style="padding: 0 40px;width: 54%">
        <div style="font-weight: bold;font-size: 15px;">器材信息</div>
        <div style="padding: 0px 0 0 20px">
          <div class="padding-top" v-if="item.sendGoods">赠送器材：
            <br/>&nbsp;&nbsp;
            <span v-for="(item,index) in JSON.parse(item.sendGoods)" :key="index">
                {{ item }} <el-divider direction="vertical"></el-divider>
              <br/>
              </span>
          </div>
          <div v-if="item.equipmentCheck!=null" class="padding-top">
            <div class="padding-top">
              器材用途：
              {{ item.equipmentCheck?.productUse }}
            </div>
            <div class="padding-top">
              器材状态：
              {{ item.equipmentCheck?.state }}
            </div>
            <div class="padding-top" v-if="item.equipmentCheck?.coach">
              器材金额：
              {{ item.equipmentCheck?.totalMoney }}元
            </div>
            <div class="padding-top" v-if="item.equipmentCheck?.coach">
              领取教练：
              <span v-if="item.equipmentCheck?.coach">
                {{ item.equipmentCheck?.coach.coachname }}
              <el-divider direction="vertical"></el-divider>
            </span>
            </div>
            <div class="padding-top">
              领取学生：
              {{ item.equipmentCheck.studentName }}
              <el-divider direction="vertical"></el-divider>
            </div>
            <div class="padding-top" v-if="item.equipmentCheck.checkCoach">
              出库审批：
              {{ item.equipmentCheck.checkCoach.coachname }}
              <el-divider direction="vertical"></el-divider>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div style="font-weight: bold;font-size: 15px;padding: 0px 40px 20px 40px">订单信息</div>
    <div style="display:flex;padding-left: 60px">
      <div style="display: flex;">
        <div class="main">
          <view class="type typecolor" style="background-color: #CDA571;color: white">{{ item.state }}</view>
          <div class="mainCardName">{{ item.cardName }}</div>
          <div style="font-size: 12px;padding:0px 26px;color: #5A4018;margin-top: 2px;">
            <div style="padding: 4px 0">
              开始时间:{{ item.startTime == null ? "待使用" : item.startTime }}
            </div>
            <div>
              结束时间:{{ item.endTime == null ? "待使用" : item.endTime }}
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
              <div>
                <div>{{ item.remaining }}</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 1">剩余课时</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 2">期卡剩余</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 3">剩余小时</div>
              </div>
              <div>
                <div>{{ item.ouse }}</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 1">已用课时</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 2">期卡已用</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 3">已用小时</div>
              </div>
              <div>
                <div>{{ item.buyNumber }}</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 1">总购课时</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 2">期卡总购</div>
                <div v-if="item.mainCardRule.subtractCourseWay == 3">总购小时</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.sendorder!=null" class="vice">
          <view class="type typecolor" style="background-color: #58a3e9;color: white">{{
              item.sendorder.state
            }}
          </view>
          <div class="viceCardName">{{ item.sendorder.cardName }}</div>
          <div style="font-size: 12px;padding:0px 26px;color: #456087;margin-top: 2px;">
            <div style="padding: 4px 0">
              开始时间:{{ item.sendorder.startTime == null ? "待使用" : item.sendorder.startTime }}
            </div>
            <div>
              结束时间:{{ item.sendorder.endTime == null ? "待使用" : item.sendorder.endTime }}
            </div>
            <div style="display: flex;justify-content: space-between;margin-top: 10px;width: 80%;">
              <div>
                <div>{{ item.sendorder.remaining }}</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 1">剩余课时</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 2">期卡剩余</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 3">剩余小时</div>
              </div>
              <div>
                <div>{{ item.sendorder.suse }}</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 1">已用课时</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 2">期卡已用</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 3">已用小时</div>
              </div>
              <div>
                <div>{{ item.sendNumber }}</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 1">总购课时</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 2">期卡总购</div>
                <div v-if="item.sendorder.cardAttributeRule.subtractCourseWay == 3">总购小时</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-left:100px;">
        <div>服务老师：{{ item.serveCoachName }} <span style="margin-left:20px">ID：{{ item.serveCoachId }}</span></div>
        <div class="padding-top">卡片金额：{{ item.money }} 元</div>
        <div class="padding-top">所属区域：{{ item.submitAreaName }}</div>
        <div class="padding-top">所属校区：{{ item.submitCampusName + " - " + item.submitCampusTypeName }}</div>
        <div class="padding-top">
          上课校区：{{ item.campusName != null ? item.campusName + " - " + item.classCampusTypeName : "" }}
        </div>
        <div class="padding-top">创建日期：{{ item.createTime }}</div>
        <div class="padding-top">备注信息：{{ item.note }}</div>
      </div>
    </div>
    <div style="font-weight: bold;font-size: 15px;padding: 20px 40px">Id列表</div>
    <span style="padding: 0 20px 0 60px">主卡Id：{{ item.orderId }}</span>
    <span style="padding: 0 20px">主卡片Id：{{ item.cardTypeId }}</span>
    <span style="padding: 0 20px" v-if="item.sendorder!= null">副卡Id：{{ item.sendorder.sid }}</span>
    <span style="padding: 0 20px" v-if="item.sendorder!= null">副卡片Id：{{ item.sendorder.typeId }}</span>
    <span style="padding: 0 20px">订单信息Id：{{ item.infoId }}</span>
    <span style="padding: 0 20px">缴费记录Id：{{ item.tradingId }}</span>
    <span style="padding: 0 20px" v-if="item.originalOrderId">原始Id：{{ item.originalOrderId }}</span>
  </div>

</template>

<script>
export default {
  name: "OrderDetail",
  props: {
    item: String,
  },
}
</script>

<style scoped>
.padding-top {
  padding-top: 10px;
}

.card {
  display: flex;
  justify-content: space-around;
  border-bottom: solid 1px #e4e7ed;
  margin-bottom: 8px;
}

.mainCardName {
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #bb9c6e, #000000);
  font-style: italic;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.viceCardName {
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(to right, #81B7EB, #000000);
  font-style: italic;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  padding: 10px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.type {
  line-height: 26px;
  font-style: italic;
  border-radius: 0px 15px 0px 15px;
  font-weight: bold;
  height: 26px;
  width: 66px;
  font-size: 12px;
  text-align: center;
  float: right;
}

.main {
  background: -webkit-linear-gradient(left, #F5DCB5, #e3c28f);
  height: 150px;
  border-radius: 15px;
  width: 260px
}

.vice {
  background: -webkit-linear-gradient(left, #C9E3F8, #81B7EB);
  height: 150px;
  border-radius: 15px;
  margin-left: 20px;
  width: 260px
}
</style>