<template>
  <el-tabs v-model="activeTab" class="demo-tabs" stretch=true style="margin: 0;padding: 0" @tab-click="handleClick">
    <el-tab-pane label="学员信息" name="first">
      <div style="padding:30px 50px 0 0">
        <el-form :model="student" :rules="studentRules" ref="studentRule" label-width="120px" class="demo-ruleForm">
          <el-row>
            <el-col :span="8">
              <el-form-item class="itemWih" label="学员姓名" prop="studentName">
                <el-input v-model="student.studentName" disabled="disabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="学员电话" prop="studentPhone">
                <el-input v-model="student.studentPhone" oninput="value=value.replace(/[^\d.]/g, '')"
                  disabled="disabled">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="学员性别" prop="studentRecord.studentSex">
                <el-select placeholder="学员性别" v-model="student.studentRecord.studentSex">
                  <el-option label="男" :value="1"></el-option>
                  <el-option label="女" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item class="itemWih" label="卡片金额" prop="money">
                <el-input v-model="student.money"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="商户单号" prop="transaction">
                <el-input v-model="student.transaction"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="收款时间" prop="collectionTime">
                <el-date-picker type="date" style="width:184px"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                  v-model="student.collectionTime" placeholder="收款时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item  label="提报校区类型">
                <el-select :size="size" placeholder="学员类型"
                  @change="changeStudentType(student.submitCampusFlag, 'submitCampus')"
                  v-model="student.submitCampusFlag">             
                  <div v-if="sysStudentType == 3">
                    <el-option label="中招体育" :value='1'></el-option>
                    <el-option label="少年" :value='2'></el-option>
                    <el-option label="高考体育" :value="4"></el-option>
                  </div>
                  <div v-if="sysStudentType != 3">
                  
                    <el-option v-if="sysStudentType == 1" label="中招体育" :value="1"></el-option>
                    <el-option v-else label="少年" :value="2"></el-option>
                    <el-option label="高考体育" :value="4"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="submitCampusId" label="提报校区">
                <el-cascader filterable :options="submitCampusAreaList" :show-all-levels="false"
                  :props="{ value: 'id', label: 'name', children: 'children' }" v-model="student.submitCampusId" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
            <el-form-item label="服务老师" prop="serveCoachName">
                <el-autocomplete
                v-model="form.keyword"
                :fetch-suggestions="querySearchAsync"
                placeholder="教练名称"
                :trigger-on-focus="false"
                :clearable="true"
                prefix-icon="el-icon-place"
                @select="selectCocahName"
              ></el-autocomplete>   
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="campusFlag" label="上课校区类型">
                <el-select :size="size" placeholder="学员类型" @change="changeStudentType(student.campusFlag, 'campus')"
                  v-model="student.campusFlag">
                  <div v-if="sysStudentType == 3">
                    <el-option label="中招体育" :value="1"></el-option>
                    <el-option label="少年" :value="2"></el-option>
                    <el-option label="高考体育" :value="4"></el-option>
                  </div>
                  <div v-if="sysStudentType != 3">
                    <el-option v-if="sysStudentType == 1" label="中招体育" :value="1"></el-option>
                    <el-option v-else label="少年" :value="2"></el-option>
                    <el-option label="高考体育" :value="4"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="campusId" label="上课校区">
                <el-cascader filterable :options="areaList" :show-all-levels="false"
                  :props="{ value: 'id', label: 'name', children: 'children' }" v-model="student.campusId" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学员学校" prop="studentRecord.studentSchool">
                <el-input placeholder="请输入学校名称" v-model="student.studentRecord.studentSchool"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="主卡总数数量" prop="buyNumber">
                <el-input :maxlength="4" :minlength="4" οninput="value=value.replace(/[^\d]/g,'')" placeholder="购买总数量"
                  type="number" v-model="student.buyNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="副卡总数数量">
                <el-input :maxlength="4" :minlength="4" οninput="value=value.replace(/[^\d]/g,'')" placeholder="赠送总数量"
                  type="number" v-model="student.sendNumber"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学员班级" prop="studentRecord.studentClass">
                <el-input placeholder="请输入班级名称" v-model="student.studentRecord.studentClass"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="display: flex">
            <el-form-item label="备注">
              <el-input style="width: 490px;" :rows="2" v-model="student.note" maxlength="300" placeholder="请输入需要备注的信息！"
                show-word-limit type="textarea" />
            </el-form-item>
            <el-col :span="8">
              <el-form-item label="学员应届" prop="studentRecord.studentGrade">
                <el-input :minlength="4" oninput="if(value.length>4)value=value.slice(0,4)" placeholder="只能填写4位数字"
                  type="number" v-model="student.studentRecord.studentGrade"></el-input>
              </el-form-item>
            </el-col>
          </div>
        </el-form>
      </div>
    </el-tab-pane>
    <el-tab-pane label="学员主卡" name="second">
      <div style="padding:40px 50px 0px 0">
        <el-form :model="mainCard" :rules="mainCardRules" ref="mainCardRules" label-width="120px" class="demo-ruleForm">
          <el-row>
            <el-col :span="8">
              <el-form-item class="itemWih" label="主卡类型" prop="cardTypeId">
                <el-select v-model="mainCard.cardTypeId" filterable default-first-option placeholder="请选择">
                  <el-option v-for="item in cardList" :key="item.id" :label="item.name" :value="item.id*1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="剩余课时" prop="remaining">
                <el-input v-model="mainCard.remaining"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="已用课时" prop="ouse">
                  <el-tag class="ml-2">已有记录课时{{orderLesson}}</el-tag>       
                <el-input v-model="mainCard.ouse"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="开始日期" >
                <el-date-picker   type="date" style="width:184px"
                                  format="YYYY-MM-DD"
                                  value-format="YYYY-MM-DD"
                                  v-model="mainCard.startTime"
                  placeholder="开始日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束日期">
                <el-date-picker   type="date" style="width:184px"
                                  format="YYYY-MM-DD"
                                  value-format="YYYY-MM-DD"
                                  v-model="mainCard.endTime"
                  placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="订单状态" prop="state">
                <el-select   v-model="mainCard.state">
                  <el-option key="已激活" label="已激活" value="已激活"></el-option>
                  <el-option key="未激活" label="未激活" value="未激活"></el-option>
                  <el-option key="已失效" label="已失效" value="已失效"></el-option>
                  <el-option key="已转卡" label="已转卡" value="已转卡"></el-option>
                  <el-option key="转卡失败" label="转卡失败" value="转卡失败"></el-option>
                  <el-option key="已退费" label="已退费" value="已退费"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="height: 200px"></div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="学员副卡" name="third">
      <div style="padding:40px 50px 0px 0">
        <el-form :model="sendCard" :rules="sendCardRules" ref="sendCardRules" label-width="120px" class="demo-ruleForm">
          <el-row>
            <el-col :span="8">
              <el-form-item class="itemWih" label="副卡 类型" prop="typeId">
                <el-select v-model="sendCard.typeId" filterable default-first-option placeholder="请选择">
                  <el-option v-for="item in cardList" :key="item.id" :label="item.name" :value="item.id*1" />
                </el-select>
              </el-form-item>

            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="剩余课时" prop="remaining">
                <el-input v-model="sendCard.remaining"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="已用课时" prop="suse">
                <el-tag  v-if="sendorderLesson" class="ml-2">已有记录课时{{sendorderLesson}}</el-tag>       
                <el-input v-model="sendCard.suse"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="开始日期">
                <el-date-picker  type="date" style="width:184px"
                                 format="YYYY-MM-DD"
                                 value-format="YYYY-MM-DD"
                                 v-model="sendCard.startTime"
                  placeholder="开始日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束日期">
                <el-date-picker  type="date" style="width:184px"
                                 format="YYYY-MM-DD"
                                 value-format="YYYY-MM-DD"
                                 v-model="sendCard.endTime"
                  placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item class="itemWih" label="订单状态" prop="state">
                <el-select  v-model="sendCard.state">
                  <el-option key="已激活" label="已激活" value="已激活"></el-option>
                  <el-option key="未激活" label="未激活" value="未激活"></el-option>
                  <el-option key="已失效" label="已失效" value="已失效"></el-option>
                  <el-option key="已转卡" label="已转卡" value="已转卡"></el-option>
                  <el-option key="转卡失败" label="转卡失败" value="转卡失败"></el-option>
                  <el-option key="已退费" label="已退费" value="已退费"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="height: 200px"></div>
      </div>
    </el-tab-pane>
  </el-tabs>
 
</template>

<script>
import { listCampusTree } from "@/api/area.js";
import { 
  updateOrderInfo, 
  updateOrder, 
  updateSendOrder, 
  getorderLesson } from "@/api/orders.js";
import { selectCardUpdateOrder } from "@/api/card";
import { pageList} from  "@/api/coach";
export default {
  name: "UpdateOrder",
  props: {
    items: String,
  },
  data() {
    return {
      annotationUpdateRecordExplain:'',
      form: {
        areaList: [],
        keyword: "",
        pageIndex: 1,
        pageSize: 6,
        state: "开启",
        type: "",
      },
      coachName:'',
      timeout: null,
      inputTipsOpen: true,
      AllCaochList:[],
      activeTab: 'first',
      sysStudentType: localStorage.getItem('studentType'),
      areaList: [],
      cardList: [],
      submitCampusAreaList: [],
      orderLesson:'',
      sendorderLesson:"",
      //学员信息对象
      student: {
        studentName: "",
        studentPhone: "",
        money: null,
        transaction: "",
        collectionTime: null,
        orderType: "",
        buyNumber: null,
        sendNumber: null,
        campusId: null,
        submitCampusId: null,
        orderId: null,
        infoId: null,
        note: "",
        studentRecord: {
          studentClass: "",//班级
          studentGrade: null,//届
          studentSchool: "",//学校
          studentSex: null,
        }
      },
      studentRules: {
        studentName: [{
          required: true,
          message: '请输入学员姓名！',
          trigger: 'change'
        },],
        studentPhone: [{
          required: true,
          message: '请输入学员电话号码！',
          trigger: 'change'
        },],
        money: [{
          required: true,
          message: '请输入购买金额！',
          trigger: 'change'
        }],
        transaction: [{
          required: true,
          message: '请输入商户单号！',
          trigger: 'change'
        }],
        collectionTime: [{
          required: true,
          message: '请选择收款日期！',
          trigger: 'change'
        }],
        orderType: [{
          required: true,
          message: '请选择学员类型！',
          trigger: 'change'
        }],
        buyNumber: [{
          required: true,
          message: '请输入购买总数量！',
          trigger: 'change'
        }],
        campusId: [{
          required: true,
          message: '请选择上课校区！',
          trigger: 'change'
        }],
        submitCampusId: [{
          required: true,
          message: '请选择提报校区！',
          trigger: 'change'
        }],
        // "studentRecord.studentSex": [{
        //   required: true,
        //   message: '请选择学员性别！',
        //   trigger: 'change'
        // }],
        // "studentRecord.studentGrade": [{
        //   required: true,
        //   message: '请输入学员届数！',
        //   trigger: 'change'
        // }]
      },
      //学员主卡对象
      mainCard: {
        cardTypeId: null,
        remaining: null,
        ouse: null,
        state: "",
        endTime: null,
        startTime: null,
        orderId: null
      },
      mainCardRules: {
        cardTypeId: [{
          required: true,
          message: '请选择学员卡片类型！',
          trigger: 'change'
        },],
        remaining: [{
          required: true,
          message: '请输入剩余数量！',
          trigger: 'change'
        },],
        ouse: [{
          required: true,
          message: '请输入已用数量！',
          trigger: 'change'
        }],
        state: [{
          required: true,
          message: '请选择订单状态！',
          trigger: 'change'
        }],
      },

      //学员副卡对象
      sendCard: {
        startTime: '',
        endTime: '',
        remaining: '',
        suse: '',
        typeId: '',
        state: null,
        orderId: null
      },
      typeId: {
        cardTypeId: [{
          required: true,
          message: '请选择学员卡片类型！',
          trigger: 'change'
        },],
        remaining: [{
          required: true,
          message: '请输入剩余数量！',
          trigger: 'change'
        },],
        suse: [{
          required: true,
          message: '请输入已用数量！',
          trigger: 'change'
        }],
        state: [{
          required: true,
          message: '请选择订单状态！',
          trigger: 'change'
        }],
      },
    }
  },
  methods: {
    selectCocahName(item){
      // this.student.serveCoachName = item.value
      this.student.serveCoachId = item.id
    },
    async  querySearchAsync(queryString, cb) {
    await  pageList(this.form).then(res=>{ 
        if(res.code==200){ 
          this.AllCaochList = res.data.records
        }
      })
      let apiResult = []
      this.AllCaochList.forEach(item=>{ 
        let obj = {}
        obj.value = item.coachName
        obj.id = item.coachId
        apiResult.push(obj)
      })
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(apiResult)
      }, 600)
    },
    changeStudentType(val, type) {
      this.getAreaList(val, type);
    },
    //获取区域列表
    getAreaList(typeId, type) {
      listCampusTree(typeId).then(val => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          if (type == 'submitCampus') {
            this.submitCampusAreaList = val.data;
          } else if (type == 'campus') {
            this.areaList = val.data;
          }
        }
      })
    },
    /**
     * 时间格式化
     */
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    /**
     * 处理区域列表中的‘children’
     */
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    /**
     * 设置学员信息值
     */
    setStudent(item) {
      this.orderLesson = ''
      this.sendorderLesson = ''
      // console.log(item)
      if(item.sendorder!=null){ 
      this.sendorder()
     }
      this.mainorder()
      this.getAreaList(item.submitCampusFlag, 'submitCampus');
      this.getAreaList(item.campus, 'campus');
      let student = this.student;
      let mainCard = this.mainCard;
      let sendCard = this.sendCard;
      student.orderId = item.orderId;
      student.serveCoachName = item.serveCoachName
      student.serveCoachId = item.serveCoachId
      this.form.keyword = item.serveCoachName
      student.studentName = item.studentName;
      student.submitCampusFlag = item.submitCampusFlag;   
      student.campusFlag = item.campusFlag;
      student.studentPhone = item.studentPhone;
      student.transaction = item.transaction;
      student.money = item.money;
      student.collectionTime = item.collectionTime;
      student.buyNumber = item.buyNumber;
      student.sendNumber = item.sendNumber;
      student.orderType = item.orderType;
      student.campusId = item.campusId;
      student.submitCampusId = item.submitCampusId;
      student.note = item.note;
      student.infoId = item.infoId;
      student.studentRecord.studentSex = item.studentRecord.studentSex;
      student.studentRecord.studentSchool = item.studentRecord.studentSchool;
      student.studentRecord.studentGrade = item.studentRecord.studentGrade;
      student.studentRecord.studentClass = item.studentRecord.studentClass;
      this.student = student;
      //设置主卡数据
      mainCard.cardTypeId = item.cardTypeId;
      mainCard.remaining = item.remaining;
      mainCard.ouse = item.ouse;
      mainCard.state = item.state;
      mainCard.endTime = item.endTime;
      mainCard.startTime = item.startTime;
      mainCard.orderId = item.orderId;
      this.mainCard = mainCard;
      //设置副卡数据
      if (item.sendorder != null) {
        sendCard.typeId = item.sendorder.typeId;
        sendCard.remaining = item.sendorder.remaining;
        sendCard.suse = item.sendorder.suse;
        sendCard.state = item.sendorder.state;
        sendCard.endTime = item.sendorder.endTime;
        sendCard.startTime = item.sendorder.startTime;
        sendCard.orderId = item.orderId;
      } else {
        sendCard.startTime = ''
        sendCard.endTime = ''
        sendCard.remaining = ''
        sendCard.suse = ''
        sendCard.typeId = ''
        sendCard.state = null
        sendCard.orderId = item.orderId
      }
      this.sendCard = sendCard;
    },
    /**
     * 确定修改
     */
    update(e) {
      this.annotationUpdateRecordExplain = e
          if (this.activeTab == 'first') {
            this.updateInfo();
          }
          if (this.activeTab == 'second') {
            this.updateMainCard();
          }
          if (this.activeTab == 'third') {
            this.updateSendCard();
          }  
    },
    /**
     * 修改学员信息
     */
    updateInfo() {
      console.log('AHSDIUHASDUIOAHUSIOD');
      this.$refs['studentRule'].validate((valid) => {
        if (valid) {
          this.student.collectionTime = this.dateFormat(this.student.collectionTime);
          if (!/^[0-9]+$/.test(this.student.campusId)) {
            this.student.campusId = this.student.campusId[this.student.campusId.length - 1];
          }
          if (!/^[0-9]+$/.test(this.student.submitCampusId)) {
            this.student.submitCampusId = this.student.submitCampusId[this.student.submitCampusId.length - 1];
          }
          this.student.annotationUpdateRecordExplain = this.annotationUpdateRecordExplain
          console.log(this.student);
          updateOrderInfo(this.student).then(value => {
            // this.$emit("updateCallback");
            if (value.code == 200) {
              this.$message.success("更新成功！");
            } else {
              this.$message.error("更新失败！");
            }
          })
        }else{ 
          console.log('a999999');
        }
      })
    },
    /**
     * 修改学员主卡
     */
    updateMainCard() {
      this.$refs['mainCardRules'].validate((valid) => {
        if (valid) {
          if (this.mainCard.startTime != null && this.mainCard.startTime != '') {
            this.mainCard.startTime = this.dateFormat(this.mainCard.startTime);
          }
          if (this.mainCard.endTime != null && this.mainCard.endTime != '') {
            this.mainCard.endTime = this.dateFormat(this.mainCard.endTime);
          }

          if (!/^[0-9]+$/.test(this.mainCard.cardTypeId)) {
            this.mainCard.cardTypeId = this.mainCard.cardTypeId[this.mainCard.cardTypeId.length - 1];
          }
          this.mainCard.annotationUpdateRecordExplain = this.annotationUpdateRecordExplain
          updateOrder(this.mainCard).then(value => {
            // this.$emit("updateCallback");
            if (value.code == 200) {
              this.$message.success("更新成功！");
            } else {
              this.$message.error("更新失败！");
            }
          })
        }
      })
    },
    /**
     * 修改学员副卡
     */
    updateSendCard() {
      this.$refs['sendCardRules'].validate((valid) => {
        if (valid) {
          if (this.sendCard.startTime != null && this.sendCard.startTime != '') {
            this.sendCard.startTime = this.dateFormat(this.sendCard.startTime);
          }
          if (this.sendCard.endTime != null && this.sendCard.endTime != '') {
            this.sendCard.endTime = this.dateFormat(this.sendCard.endTime);
          }

          if (!/^[0-9]+$/.test(this.sendCard.typeId)) {
            this.sendCard.typeId = this.sendCard.typeId[this.sendCard.typeId.length - 1];
          }
          this.sendCard.annotationUpdateRecordExplain = this.annotationUpdateRecordExplain
          updateSendOrder(this.sendCard).then(value => {
            // this.$emit("updateCallback");
            if (value.code == 200) {
              this.$message.success("更新成功！");
            } else {
              this.$message.error("更新失败！");
            }
          })
        }
      })
    },

    selectCardUpdateOrder(typeId) {

      selectCardUpdateOrder(typeId).then(val => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          this.cardList = val.data;
        }
      })
    },
    //学员主卡使用课时
    mainorder(){ 
      let type = 1
      this.orderLesson = 0
      getorderLesson(this.items.orderId,type).then(res=>{ 
        if(res.code==200){ 
          this.orderLesson = res.data
          // console.log( this.orderLesson)
        }
      })
    },
    //学员副卡使用课时
    sendorder(){ 
      let type = 2
      // console.log(this.items.sendorder);
      getorderLesson(this.items.sendorder.sid,type).then(res=>{ 
        // console.log('学员副卡课时',res.data);
       if(res.code==200){ 
        this.sendorderLesson = res.data
       }
      })
    }
  },
  created() {
    // console.log('页面打开');
   
    this.setStudent(this.items);
    this.selectCardUpdateOrder(this.items.campusId);
  },
  watch: {
    items(newV) {
      this.activeTab = 'first',
        this.setStudent(newV);
      this.selectCardUpdateOrder(newV.campusId);
    },
  }
}
</script>

<style scoped>
.itemWih {
  position: relative;
}

.ml-2 {
  position: absolute;
  bottom: 45px;
  left: 0;
}
</style>